import { defineStore } from 'pinia';
import {
  resourceType,
  type UserResource,
} from '@/services/room-inventory.service.types';
import { roomInventoryService } from '@/services';
import { handleAxiosError } from '@/utils/error';
import { toast } from '@/utils/toast';

export interface RoomInventory {
  templates?: UserResource[];
}

export const useRoomInventoryStore = defineStore('room-inventory', {
  state: (): RoomInventory => ({
    templates: undefined,
  }),
  actions: {
    async getMyTemplates() {
      try {
        const res = await roomInventoryService.getMyResourcesByType(
          resourceType.Template,
        );
        this.templates = res.data;
      } catch (err) {
        console.log(err);
        const { message } = handleAxiosError(err);
        toast.error({
          title: 'There was an error getting your inventory',
          message: message,
        });
      }
    },
  },
});
