<script setup lang="ts">
import GenericModal from '@/components/modals/GenericModal.vue';
import { computed, ref, watch } from 'vue';
import ButtonComponent from '../common/ButtonComponent.vue';
import { Resource } from '@/services/room-inventory.service.types';
import { usePaymentsStore } from '@/stores';
import { toast } from '@/utils/toast';
import { handleAxiosError } from '@/utils/error';
import { useResourcesStore } from '@/stores/resources';
import SpinnerLoader from '../common/SpinnerLoader.vue';

interface Props {
  modelValue: boolean;
  product: Resource;
}

const props = withDefaults(defineProps<Props>(), {});
const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();

const paymentsStore = usePaymentsStore();
const resourcesStore = useResourcesStore();
const quantity = ref(1);
const totalPrice = computed(
  () => resourcesStore.checkout.product!.price * quantity.value,
);

watch(quantity, () => (resourcesStore.checkout.quantity = quantity.value));

const show = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const insuficientBalance = computed(() => {
  if (resourcesStore.checkout.product?.price)
    return paymentsStore.coins! < totalPrice.value;
  return false;
});

const productType = computed(() => {
  if (props.product.type === 'HOUSE_BUILDING') {
    return 'House';
  } else if (props.product.type === 'HALL_OF_FAME_BUILDING') {
    return 'Hall Of Fame';
  }
  return '';
});

const isLoading = ref(false);

async function purchase() {
  isLoading.value = true;

  const loadingToast = toast.loading('Processing payment...');

  try {
    const result = await resourcesStore.checkoutConfirm();

    loadingToast.resolve({
      title: 'Purchase completed succesfully',
      message: `${result?.product.name} was added to your inventory.`,
    });

    show.value = false;
  } catch (error) {
    const { message } = handleAxiosError(error);
    loadingToast.reject({
      title: 'There was an error processing the payment',
      message: message,
    });
  } finally {
    isLoading.value = false;
  }
}
</script>

<template>
  <GenericModal v-model="show" :title="`Buy ${productType} ${product.name}`">
    <template #default>
      <div
        v-if="product.images[0]"
        class="overflow-hidden rounded-lg bg-darker align-top"
      >
        <img
          class="mx-auto size-full max-h-[500px] object-cover"
          :src="product.images[0]"
        />
      </div>
      <SpinnerLoader v-else class="h-[450px]" />
      <div>
        <div class="mt-2 flex min-h-20 max-w-lg justify-between gap-3">
          <p>
            {{ product.description }}
          </p>

          <div class="flex min-w-20 flex-col items-center">
            <img width="70" src="/images/CoinAnim.gif" alt="" />
            <span
              class="-mt-2 mr-[-0.23rem] font-poppins text-2xl font-extrabold tracking-[0.15em] text-brand-yellow"
            >
              {{ totalPrice }}
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="product.type === 'ASSET'"
        class="my-3 w-fit rounded-md bg-darkestgray py-1 pl-3"
      >
        <label for="quantity">Quantity</label>
        <input
          id="quantity"
          v-model="quantity"
          type="number"
          class="mx-3 w-10 bg-transparent outline-none"
          min="1"
          max="99"
        />
      </div>
      <div
        v-if="insuficientBalance"
        class="mt-4 flex items-center justify-between gap-2"
      >
        <div class="flex items-center gap-2">
          <span class="mt-1 font-poppins font-semibold text-brand-yellow"
            >My balance</span
          >

          <div class="flex items-center">
            <img width="30" src="/images/CoinAnim.gif" alt="" />
            <span
              class="mt-1 font-poppins font-extrabold tracking-[0.15em] text-brand-yellow"
            >
              {{ paymentsStore.coins }}
            </span>
          </div>
        </div>
        <router-link
          :to="{ name: 'BalanceView' }"
          @click="resourcesStore.checkoutCancel()"
        >
          <ButtonComponent class="text-sm" width="xs" variant="secondary">
            Buy legends
          </ButtonComponent>
        </router-link>
      </div>
    </template>
    <template #footer>
      <ButtonComponent
        :disabled="insuficientBalance || quantity < 1"
        class="w-full"
        :is-loading="isLoading"
        @click="purchase"
      >
        {{ insuficientBalance ? 'Not enough legends' : 'Pay with legends' }}
      </ButtonComponent>
    </template>
  </GenericModal>
</template>

<style scoped lang="scss"></style>
