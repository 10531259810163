import { useAuthStore } from '@/stores';
import { computed, ref } from 'vue';
import type { TokenToUSDT } from '@/services/wallet.service.types';
import { useWalletStore } from '@/stores/wallet';
import {
  ContractType,
  type GetTransactionHistoryReturn,
  SequenceIndexer,
  type GetTokenBalancesReturn,
  type TokenBalance,
  type TxnTransfer,
} from '@0xsequence/indexer';
import { isSentTransactionResponse } from '@0xsequence/waas';
import { USDT, BNB, MATIC } from '@/utils/wallet/tokensByDefault';
import { handleAxiosError } from '@/utils/error';

type Coins = Record<string, string>;

export interface Chain {
  name: string;
  endpoint: string;
  icon: string;
  token: string;
}

const useWallet = () => {
  const authStore = useAuthStore();

  const walletStore = useWalletStore();
  const { sequence } = useAuthStore();
  const isLoading = ref(false);

  const coins: Coins = {
    BNB: 'cryptocurrency-color:bnb',
    MATIC: 'cryptocurrency-color:matic',
    USDT: 'cryptocurrency-color:usdt',
    USDC: 'cryptocurrency-color:usdc',
  } as const;

  const chains: Chain[] = [
    { name: 'Polygon', endpoint: 'amoy', icon: coins.MATIC, token: 'MATIC' },
    { name: 'BSC', endpoint: 'bsc-testnet', icon: coins.BNB, token: 'BNB' },
  ] as const;

  const coinsValue = ref<TokenToUSDT[]>();
  const currentToken = ref<GetTokenBalancesReturn>();
  const currentNFT = ref<GetTokenBalancesReturn>();
  const currentActivity = ref<GetTransactionHistoryReturn>();

  const transactionsList = computed(() =>
    walletStore.transactions?.transactions.map(
      ({ blockHash, timestamp, transfers, txnHash, chainId }) => ({
        blockHash,
        timestamp,
        transfers,
        txnHash,
        chainId,
      }),
    ),
  );

  const nftList = computed(() =>
    walletStore.tokenBalances?.balances.filter(
      (nft) => nft.contractType !== ContractType.ERC20,
    ),
  );

  const tokensList = computed(() => {
    const defaultTokens = [BNB, MATIC, USDT];

    // Si no hay balances disponibles, devolvemos los tokens predeterminados.
    if (!walletStore.tokenBalances?.balances) {
      return defaultTokens;
    }

    // Mapeamos los tokens predeterminados y reemplazamos el balance si existe en tokenBalances.
    const mappedTokens = defaultTokens.map((defaultToken) => {
      // Verificamos si el token predeterminado es el token nativo.
      if (defaultToken.contractInfo?.symbol === walletStore.tokenName) {
        return {
          ...defaultToken,
          balance: walletStore.mainToken?.balance.balanceWei, // Reemplazamos el balance por el balance nativo
          contractType: ContractType.NATIVE, // Cambiamos el contractType a NATIVE
        };
      }

      // Buscamos un token que coincida con el contractAddress en los balances del usuario.
      const matchingToken = walletStore.tokenBalances?.balances.find(
        (token) => token.contractAddress === defaultToken.contractAddress,
      );

      // Si encontramos un token en los balances del usuario, lo usamos, si no, dejamos el predeterminado.
      return matchingToken ? matchingToken : defaultToken;
    });

    // Filtramos tokens adicionales que no están en los tokens predeterminados y que son ERC20.
    const additionalTokens = walletStore.tokenBalances.balances.filter(
      (token) =>
        !defaultTokens.some(
          (defaultToken) =>
            defaultToken.contractAddress === token.contractAddress,
        ) && token.contractType === ContractType.ERC20,
    );

    // Combinamos los tokens mapeados con los tokens adicionales y los devolvemos.
    return mappedTokens.concat(additionalTokens);
  });

  const currentTokensSymbol = computed(() =>
    tokensList.value
      .filter((token) => token.contractInfo?.symbol !== 'USDT')
      .map(
        (token) =>
          `${token.contractInfo?.symbol ? token.contractInfo.symbol : ''}USDT`,
      ),
  );

  const mainTokenFormatted = computed(
    () =>
      walletStore.mainToken &&
      Number(walletStore.mainToken.balance.balanceWei) / 1000000000000000000,
  );

  async function sendToken(
    tokenID: string,
    contractAddress: string,
    toWallet: string,
    contractType:
      | ContractType.ERC1155
      | ContractType.ERC721
      | ContractType.ERC20
      | ContractType.NATIVE,
    amount: number,
  ) {
    if (!sequence) return;
    isLoading.value = true;

    if (contractType === ContractType.ERC721) {
      const tx = await sequence.sendERC721({
        token: contractAddress,
        to: toWallet,
        id: tokenID,
      });

      if (isSentTransactionResponse(tx)) {
        return tx;
      }
    }

    if (contractType === ContractType.ERC1155) {
      const tx = await sequence.sendERC1155({
        token: contractAddress,
        to: toWallet,
        values: [{ amount, id: tokenID }],
      });

      if (isSentTransactionResponse(tx)) {
        return tx;
      }
    }
    if (contractType === ContractType.ERC20) {
      const tx = await sequence.sendERC20({
        token: contractAddress,
        to: toWallet,
        value: amount,
      });

      if (tx.code === 'transactionFailed') {
        const error = new Error(tx.data.error);
        handleAxiosError(error, true);
      }
      if (isSentTransactionResponse(tx)) {
        return tx;
      }
    }

    if (contractType === ContractType.NATIVE) {
      const tx = await sequence.sendTransaction({
        transactions: [
          {
            to: toWallet,
            value: amount,
          },
        ],
      });

      if (tx.code === 'transactionFailed') {
        const error = new Error(tx.data.error);
        handleAxiosError(error, true);
      }
      if (isSentTransactionResponse(tx)) {
        await walletStore.getIndexer(true);
        return tx;
      }
    }

    isLoading.value = false;
  }

  function getTransactionBalance(amount: string, _decimals: number) {
    const decimals = '0'.repeat(_decimals);
    return Number(amount) / Number(`1${decimals}`);
  }

  function getTokenBalance(token: TokenBalance) {
    if (token.contractType === ContractType.NATIVE)
      return Number(token.balance) / 1000000000000000000;
    const decimals = token.contractInfo?.decimals
      ? '0'.repeat(token.contractInfo.decimals)
      : '';
    return Number(token.balance) / Number(`1${decimals}`);
  }

  function addDecimals(amount: number, token: TokenBalance) {
    if (token.contractType === ContractType.NATIVE)
      return amount * 1000000000000000000;
    const decimals = token.contractInfo?.decimals
      ? '0'.repeat(token.contractInfo.decimals)
      : '';
    return amount * Number(`1${decimals}`);
  }

  function getTransferBalance(transfer: TxnTransfer) {
    if (transfer.contractType === ContractType.NATIVE)
      return Number(transfer.amounts[0]) / 1000000000000000000;
    const decimals = transfer.contractInfo?.decimals
      ? '0'.repeat(transfer.contractInfo.decimals)
      : '';
    return Number(transfer.amounts[0]) / Number(`1${decimals}`);
  }

  function getActivityByToken(
    contractAddress: string,
    contractType: ContractType,
  ) {
    if (!walletStore.client) {
      walletStore.client = new SequenceIndexer(
        `https://${walletStore.chainEndpoint}-indexer.sequence.app`,
        import.meta.env.VITE_PROJECT_ACCESS_KEY,
      );
    }

    if (contractType === ContractType.NATIVE) {
      currentActivity.value = {
        page: {},
        transactions: (walletStore.transactions?.transactions ?? []).filter(
          (t) => t.transfers?.[0].contractType === ContractType.NATIVE,
        ),
      };
      return;
    }

    currentActivity.value = {
      page: {},
      transactions: (walletStore.transactions?.transactions ?? []).filter(
        (transaction) =>
          transaction.transfers?.[0].contractAddress === contractAddress,
      ),
    };
  }

  async function getTokenById(tokenID: string, contractAddress: string) {
    if (!walletStore.client) {
      walletStore.client = new SequenceIndexer(
        `https://${walletStore.chainEndpoint}-indexer.sequence.app`,
        import.meta.env.VITE_PROJECT_ACCESS_KEY,
      );
    }

    const currentHarcodedToken = tokensList.value.find(
      (token) => token.contractAddress === contractAddress,
    );

    if (currentHarcodedToken) {
      currentToken.value = {
        page: {},
        balances: [currentHarcodedToken as TokenBalance],
      };
      walletStore.currentToken = currentToken.value;

      return;
    }

    currentToken.value = await walletStore.client.getTokenBalances({
      includeMetadata: true,
      accountAddress: authStore.walletAddress.wallet,
      includeCollectionTokens: true,
      contractAddress,
      tokenID,
    });

    walletStore.currentToken = currentToken.value;
  }

  async function getNftById(tokenID: string, contractAddress: string) {
    isLoading.value = true;
    if (!walletStore.client) {
      walletStore.client = new SequenceIndexer(
        `https://${walletStore.chainEndpoint}-indexer.sequence.app`,
        import.meta.env.VITE_PROJECT_ACCESS_KEY,
      );
    }

    currentNFT.value = await walletStore.client.getTokenBalances({
      includeMetadata: true,
      accountAddress: authStore.walletAddress.wallet,
      includeCollectionTokens: true,
      contractAddress,
      tokenID,
    });

    walletStore.currentNFT = currentNFT.value.balances[0];

    isLoading.value = false;
  }

  return {
    //data
    myWallet: computed(() => authStore.walletAddress.wallet),
    coinsValue,
    coins,
    chains,
    transactionsList,
    currentToken,
    mainTokenFormatted,
    currentActivity,
    currentTokensSymbol,
    nftList,
    tokensList,
    isLoading,
    //methods
    getTokenBalance,
    getTransferBalance,
    getTransactionBalance,
    getNftById,
    getTokenById,
    getActivityByToken,
    sendToken,
    addDecimals,
  };
};

export default useWallet;
