<template>
  <Modal v-model="show" title="Checkout">
    <template #default>
      <div class="image overflow-hidden rounded-lg bg-white align-top">
        <img class="mx-auto max-h-[300px]" :src="product.images[0]" />
      </div>
      <div class="mt-2">
        <h1 class="text-lg font-bold text-darksecondary">
          {{ product.name }}
        </h1>
        <p class="text-md font-light italic">{{ product.description }}</p>
      </div>
      <div class="mt-4 text-center text-white">
        <p class="text-3xl font-bold">
          {{ product.default_price.currency.toLocaleUpperCase() }}
          {{ formatPrice(product.default_price.unit_amount) }}
        </p>
      </div>
      <div class="my-4 flex flex-col justify-center">
        <div class="flex items-center justify-center gap-4">
          <div
            class="flex cursor-pointer items-center justify-center gap-3 rounded-md border-2 border-[#3F3F3F] bg-backgrounds-darkgrey px-4 py-2 tracking-wider text-darksecondary transition-all"
            :class="{ 'border-primary': selectedPayment == 'card' }"
          >
            <IconifyIcon width="1.4em" icon="ion:card-outline" /> Card
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <ButtonComponent class="w-full" :is-loading="isLoading" @click="purchase">
        Pay
      </ButtonComponent>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/modals/GenericModal.vue';
import { computed, ref } from 'vue';
import { Product } from '@/services';
import useCheckout from '@/composables/useCheckout';
import { formatPrice } from '@/utils/format';
import ButtonComponent from '../common/ButtonComponent.vue';

const { redirectToStripe } = useCheckout();

interface Props {
  modelValue: boolean;
  product: Product;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();

const show = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const selectedPayment = ref<'card'>('card');
const isLoading = ref(false);

async function purchase() {
  isLoading.value = true;

  try {
    await redirectToStripe();
  } catch (error) {
    isLoading.value = false;
  }
}
</script>

<style scoped lang="scss"></style>
