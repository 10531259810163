<template>
  <div class="microsoft-logo">
    <div class="square red"></div>
    <div class="square green"></div>
    <div class="square blue"></div>
    <div class="square yellow"></div>
  </div>
</template>

<style scoped lang="scss">
.microsoft-logo {
  width: 22px;
  margin: 1px auto;
}

.square {
  width: 10px;
  height: 10px;
  margin: 0 1px 1px 0;
  float: left;
}

.square.red {
  background-color: #f35325;
}

.square.green {
  background-color: #81bc06;
}

.square.blue {
  background-color: #05a6f0;
}

.square.yellow {
  background-color: #ffba08;
}
</style>
