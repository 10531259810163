<script setup lang="ts">
import { usePaymentsStore } from '@/stores/payments';

const paymentsStore = usePaymentsStore();
</script>

<template>
  <router-link to="/balance">
    <div class="flex items-center">
      <img width="38" src="/images/CoinAnim.gif" alt="" />
      <span class="ml-1 mt-1 font-bold text-brand-yellow">{{
        paymentsStore.coins
      }}</span>
    </div>
  </router-link>
</template>

<style scoped lang="scss"></style>
