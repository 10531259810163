<script setup lang="ts">
import { ref, onBeforeUnmount } from 'vue';
import RecoveryPasswEmail from '@/components/modals/auth/RecoveryPasswEmail.vue';
import ConfirmNewPassword from '@/components/modals/auth/ConfirmNewPassword.vue';
import { toast } from '@/utils/toast';

const trueToken = ref(false);
const passwordIsChanged = ref(false);
const userEmail = ref('');
const tokenCode = ref('');

const emits = defineEmits<{
  'change:view': [view: 'login' | 'register' | 'recovery'];
}>();

onBeforeUnmount(() => {
  passwordIsChanged.value = false;
});

interface TokenFlow {
  trueToken: true;
  email: string;
  code: string;
}

const handleUpdateTrueToken = (data: TokenFlow) => {
  trueToken.value = data.trueToken;
  userEmail.value = data.email;
  tokenCode.value = data.code;
};

function handlePasswordChange() {
  emits('change:view', 'login');
  toast.success({
    title: 'Password changed succesfully',
    message: 'Please login again with your new password.',
  });
}
</script>

<template>
  <div class="mt-5">
    <RecoveryPasswEmail
      v-if="!trueToken"
      @update="handleUpdateTrueToken"
      @back="emits('change:view', 'login')"
    />
    <ConfirmNewPassword
      v-else
      :email="userEmail"
      :code="tokenCode"
      @update="handlePasswordChange"
      @back="emits('change:view', 'login')"
    />
  </div>
</template>

<style lang="scss" scoped>
.index {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #161616;
  overflow: auto;
  color: white;
  justify-content: center;

  .container {
    width: 360px;

    @media (max-width: '450px') {
      width: 300px;
    }

    .legendaryum-logo {
      height: 200px;
    }

    .social-button {
      background-position: 25px 0px;
      box-sizing: border-box;
      color: rgb(255, 255, 255);
      cursor: pointer;
      display: inline-block;
      height: 50px;
      line-height: 50px;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      vertical-align: middle;
      width: 100%;
      border-radius: 30px;
      outline: rgb(255, 255, 255) none 0px;
      transition: all 0.2s cubic-bezier(0.72, 0.01, 0.56, 1) 0s;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      width: 350px;
      margin-bottom: 10px;

      @media (max-width: '450px') {
        width: 300px;
      }
    }
  }
}
</style>
