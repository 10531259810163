<script setup lang="ts">
import { computed, watch } from 'vue';
import { useField } from 'vee-validate';
import { AnySchema, Schema } from 'yup';
import { toTypedSchema } from '@vee-validate/yup';

type InputValue = string | number | undefined | Date;

interface Props {
  modelValue: InputValue;
  name: string;
  label?: string | undefined;
  placeholder?: string | undefined;
  type: 'text' | 'number' | 'date' | 'email';
  disabled?: boolean;
  rules?: AnySchema;
  height?: string;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  'update:modelValue': [value: InputValue];
}>();

defineSlots<{
  append(): void;
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    handleChange(value);
    emit('update:modelValue', value);
  },
});

const fieldSchema = props.rules
  ? toTypedSchema(props.rules as Schema)
  : undefined;

const { handleChange, errorMessage, setValue } = useField(
  props.name,
  fieldSchema,
  {
    initialValue: props.modelValue,
  },
);

watch(
  () => props.modelValue,
  (newVal) => {
    setValue(newVal);
  },
);
</script>

<template>
  <label class="form-control">
    <span v-if="label" class="mb-1 text-sm">{{ label }}</span>
    <div class="relative flex">
      <input
        v-model="value"
        :placeholder="placeholder"
        :name="name"
        :disabled="disabled"
        :type="type || 'text'"
        :class="{
          'input-error': errorMessage,
        }"
        class="input input-sm w-full bg-white text-base text-darkestgray"
        :style="{ height: `${height ?? '32px'}` }"
      />
      <slot name="append" />
    </div>
    <div class="label pt-0.5">
      <span class="text-sm text-red-500">{{ errorMessage }}</span>
    </div>
  </label>
</template>

<style scoped lang="scss">
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
