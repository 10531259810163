import './assets/main.css';
import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import type { FrontEnd } from '@/services';
import type { Emitter } from 'mitt';
import mitt from 'mitt';
import vue3GoogleLogin from 'vue3-google-login';
import { MotionPlugin } from '@vueuse/motion';
import { Icon } from '@iconify/vue';
import type { Plugin, Component } from 'vue';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { vueQueryOptions } from '@/plugins/vue-query';
import VueLazyload from 'vue-lazyload';
import { createHead } from '@unhead/vue';
import { notivue } from './plugins/notivue';
import { useAuthStore } from './stores';
import DOMPurify from 'dompurify';

const emitter: Emitter<FrontEnd> = mitt<FrontEnd>();

const app = createApp(App as Component);

app.config.globalProperties.emitter = emitter;
app.provide('emitter', emitter);

const pinia = createPinia();
pinia.use(({ store }) => {
  store.router = markRaw(router);
  store.emmiter = markRaw(emitter);
});

app.use(pinia);
app.use(router);
app.use(MotionPlugin as Plugin);
app.use(VueLazyload, {
  preLoad: 1.3,
  error: 'https://fakeimg.pl/736x411/3b3b3b/3b3b3b',
  loading: 'https://fakeimg.pl/736x411/3b3b3b/3b3b3b',
  attempt: 1,
});
app.use(notivue);
app.component('IconifyIcon', Icon);
app.provide('emitter', emitter);

app.directive('safe-html', (el: HTMLElement, binding) => {
  el.innerHTML = DOMPurify.sanitize(binding.value as string);
});

app.use(vue3GoogleLogin, {
  clientId: useAuthStore().googleClientId,
});

app.use(VueQueryPlugin, vueQueryOptions);

app.use(createHead());

app.mount('#app');
