<script setup lang="ts">
import { computed } from 'vue';
import GenericModal from '@/components/modals/GenericModal.vue';
import useProfile from '@/composables/useProfile';
import ButtonComponent from '../common/ButtonComponent.vue';

const { user } = useProfile();

interface Props {
  modelValue: boolean;
  coins?: number;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();

const show = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>

<template>
  <GenericModal
    v-model="show"
    container-class="primary-gradient"
    title-class="text-2xl font-bold"
    :title="`Hello @${user?.username}!`"
  >
    <div class="flex flex-col text-white sm:text-lg">
      <div class="flex items-center">
        <p class="text-balance">
          We welcome you with Legends to start building your own virtual world.
        </p>
        <div class="my-2 flex flex-col items-center text-brand-yellow">
          <img class="size-16" src="/images/coinPile.png" alt="Coins" />
          <span class="w-36 text-center font-bold sm:text-xl"
            >{{ coins }} Legends</span
          >
        </div>
      </div>
      <p class="text-balance">
        Choose your avatar, use the coins to buy islands, assets or skins for
        your game.
      </p>
      <img
        class="w-full"
        src="/images/welcome-message-image.png"
        alt="Welcome Message"
      />
      <div class="flex size-full flex-col items-center">
        <router-link :to="{ name: 'MyRoomsView' }" @click="show = false">
          <ButtonComponent
            variant="secondary"
            class="mt-4"
            height="lg"
            width="lg"
            @click="show = false"
          >
            Choose my Island!
          </ButtonComponent>
        </router-link>
      </div>
    </div>
  </GenericModal>
</template>

<style scoped lang="scss"></style>
