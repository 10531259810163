<script lang="ts" setup>
import LogoutModal from '@/components/modals/auth/LogoutModal.vue';
import useProfile from '@/composables/useProfile';
import useSubscriptions from '@/composables/useSubscriptions';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { ref } from 'vue';
import UserBlockedModal from '@/components/user/UserBlockedModal.vue';
import { useSocialStore } from '@/stores';
import WalletModal from '@/components/modals/WalletModal.vue';
import { useWalletStore } from '@/stores/wallet';

const walletStore = useWalletStore();
const showLogoutModal = ref(false);
const { avatar, userName, fullName } = useProfile();
const { currentPaidSubscription } = useSubscriptions();
const showBlockedModal = ref(false);
const socialStore = useSocialStore();

function logoutClickHandler(callback: () => void) {
  showLogoutModal.value = true;
  callback();
}
</script>

<template>
  <Popover v-slot="{ open }" class="relative flex">
    <PopoverButton ref="buttonEl" class="relative outline-none" as="button">
      <div class="aspect-square w-10 overflow-hidden rounded-full border-2">
        <img :src="avatar" tabindex="0" class="object-cover align-top" />
      </div>
    </PopoverButton>
    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel
        v-show="open"
        ref="panelEl"
        v-slot="{ close }"
        static
        class="absolute -right-6 top-8 z-10 min-w-[360px] scale-90 sm:right-0 sm:top-12 sm:scale-100"
      >
        <div
          class="flex h-fit flex-col items-center gap-3 rounded-xl bg-backgrounds-darkgrey p-4"
        >
          <div
            class="flex w-full rounded-lg bg-primary bg-gradient-to-b text-white"
          >
            <div class="flex w-full">
              <div class="flex w-full flex-col gap-2 text-[11px]">
                <div class="flex items-center pl-4 pr-1">
                  <router-link
                    :to="{ name: 'ProfileView' }"
                    class="inline-block"
                  >
                    <div
                      class="aspect-square w-[70px] overflow-hidden rounded-full"
                    >
                      <img class="w-full align-top" :src="avatar" />
                    </div>
                  </router-link>
                  <div class="ml-4 flex flex-col justify-center">
                    <span class="text-base font-bold leading-3 sm:text-lg">{{
                      fullName
                    }}</span>
                    <p class="text-xs sm:text-sm">@{{ userName }}</p>
                  </div>
                  <div class="ml-auto">
                    <router-link :to="{ name: 'SubscriptionsView' }">
                      <img
                        width="100"
                        :src="currentPaidSubscription?.images[0]"
                      />
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex h-fit w-full flex-col items-center">
            <ul class="w-full gap-1 px-3 text-sm">
              <li class="pb-2.5">
                <router-link
                  to="/subscriptions"
                  class="flex cursor-pointer items-center gap-3 text-darkGolden transition-all duration-150 hover:text-yellow-400"
                  @click="close"
                >
                  <IconifyIcon
                    width="1.2em"
                    icon="bi:star-fill"
                    class="size-5"
                  />
                  <p class="font-bold">Legendaryum subscription</p>
                </router-link>
              </li>

              <li class="py-2.5">
                <router-link
                  to="/profile"
                  class="flex cursor-pointer items-center gap-3 transition-all duration-150 hover:text-white"
                  @click="close"
                >
                  <IconifyIcon icon="ph:user-bold" class="size-5" />
                  <p class="-ml-1 font-bold">Profile</p>
                </router-link>
              </li>
              <li class="py-2.5">
                <router-link
                  to="/favorites"
                  class="flex cursor-pointer items-center gap-3 transition-all duration-150 hover:text-white"
                  @click="close"
                >
                  <IconifyIcon icon="lucide:star" class="size-5" />
                  <p class="-ml-1 font-bold">Favorites</p>
                </router-link>
              </li>

              <li
                v-if="socialStore._socialUser?.blockedUsers?.length"
                class="py-2.5"
              >
                <button
                  class="flex cursor-pointer items-center gap-3 transition-all duration-150 hover:text-white"
                  @click="
                    () => {
                      close();
                      showBlockedModal = true;
                    }
                  "
                >
                  <IconifyIcon icon="lets-icons:lock" class="size-5" />
                  <p class="-ml-1 font-bold">Blocked users</p>
                </button>
              </li>

              <li class="hidden py-2.5 lg:block">
                <button
                  class="flex cursor-pointer items-center gap-3 transition-all duration-150 hover:text-white"
                  @click="
                    () => {
                      close();
                      walletStore.showWalletModal = true;
                    }
                  "
                >
                  <IconifyIcon icon="ion:wallet-outline" />
                  <p class="-ml-1">Legend Wallet</p>
                </button>
              </li>

              <li class="block py-2.5 md:hidden">
                <router-link
                  to="/user/rooms"
                  class="flex cursor-pointer items-center gap-3 transition-all duration-150 hover:text-white"
                  @click="close"
                >
                  <IconifyIcon icon="tabler:door" class="size-5" />
                  <p class="-ml-1 font-bold">My virtual world</p>
                </router-link>
              </li>

              <li class="block py-2.5 md:hidden">
                <router-link
                  to="/missions"
                  class="flex cursor-pointer items-center gap-3 transition-all duration-150 hover:text-white"
                  @click="close"
                >
                  <IconifyIcon icon="mdi:target" class="size-5" />
                  <p class="-ml-1 font-bold">Missions to earn</p>
                </router-link>
              </li>
              <!-- <li class=" pt-2 pb-1">
        <router-link to="/" class="p-0 hover:bg-transparent">
          <IconifyIcon icon="" />
          <p class="-ml-1">Mis Activos Digitales</p>
          <span>$</span>
        </router-link>
      </li>
      <li class=" pt-2 pb-1">
        <router-link to="/" class="p-0 hover:bg-transparent">
          <IconifyIcon icon="gravity-ui:gear" />
          <p class="-ml-1">Configuración</p>
          <span>$</span>
        </router-link>
      </li>
    -->
              <li class="py-2.5">
                <router-link
                  to="/report"
                  class="flex cursor-pointer items-center gap-3 transition-all duration-150 hover:text-white"
                  @click="close"
                >
                  <IconifyIcon
                    icon="eva:alert-triangle-outline"
                    class="size-5"
                  />
                  <p class="-ml-1 font-bold">Report</p>
                </router-link>
              </li>
              <li class="py-2.5" @click="logoutClickHandler(close)">
                <div
                  class="flex cursor-pointer items-center gap-3 transition-all duration-150 hover:text-white"
                >
                  <IconifyIcon icon="lucide:log-out" class="size-5" />
                  <p class="-ml-1 text-sm font-bold">Sign off</p>
                </div>
              </li>
              <LogoutModal v-model="showLogoutModal" />
            </ul>
          </div>
          <div
            class="-mb-4 w-1/3 rounded-t-lg border-b-4 border-softGray"
          ></div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
  <UserBlockedModal v-model="showBlockedModal" />
  <WalletModal v-model="walletStore.showWalletModal" />
</template>
