import { ContractType, type TokenBalance } from '@0xsequence/indexer';

// TODO: todas estas address funciona en testnet, y en mainnet ??
export const BNB: TokenBalance = {
  contractType: ContractType.ERC20,
  contractAddress: '0xB8c77482e45F1F44dE1745F52C74426C631bDD52',
  accountAddress: '',
  balance: '0',
  blockHash: '0xBlockHashHere', // Reemplaza con el hash del bloque
  blockNumber: 0,
  chainId: import.meta.env.VITE_ENV === 'prod' ? 56 : 97,
  contractInfo: {
    chainId: import.meta.env.VITE_ENV === 'prod' ? 56 : 97,
    address: '0xB8c77482e45F1F44dE1745F52C74426C631bDD52',
    name: 'BNB',
    type: 'ERC20',
    symbol: 'BNB',
    decimals: 18,
    logoURI: 'https://example.com/logoBNB.png', // Reemplaza con la URI del logo
    deployed: true,
    bytecodeHash: '0xBytecodeHashHere', // Reemplaza con el hash del bytecode
    extensions: {
      link: 'https://www.binance.com/',
      description:
        'BNB is the cryptocurrency coin that powers the BNB Chain ecosystem.',
      ogImage: '', // Reemplaza con la imagen OG
      originChainId: import.meta.env.VITE_ENV === 'prod' ? 56 : 97,
      originAddress: '0xB8c77482e45F1F44dE1745F52C74426C631bDD52',
      blacklist: false,
      verified: true,
      verifiedBy: 'Binance',
      featured: true,
    },
    updatedAt: new Date().toISOString(),
  },
  tokenMetadata: {
    tokenId: '',
    name: 'Binance Coin',
    description:
      'BNB is the cryptocurrency coin that powers the BNB Chain ecosystem.',
    attributes: [],
    updatedAt: new Date().toISOString(),
  },
};

export const MATIC: TokenBalance = {
  contractType: ContractType.ERC20,
  contractAddress: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD',
  accountAddress: '',
  balance: '0',
  blockHash: '0xBlockHashHere', // Reemplaza con el hash del bloque
  blockNumber: 0,
  chainId: import.meta.env.VITE_ENV === 'prod' ? 137 : 80002,
  contractInfo: {
    chainId: import.meta.env.VITE_ENV === 'prod' ? 137 : 80002,
    address: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD',
    name: 'MATIC',
    type: 'ERC20',
    symbol: 'MATIC',
    decimals: 18,
    logoURI: 'https://example.com/logoMATIC.png', // Reemplaza con la URI del logo
    deployed: true,
    bytecodeHash: '0xBytecodeHashHere', // Reemplaza con el hash del bytecode
    extensions: {
      link: 'https://polygon.technology/',
      description: 'MATIC is the native currency of the Polygon network.',
      ogImage: '', // Reemplaza con la imagen OG
      originChainId: import.meta.env.VITE_ENV === 'prod' ? 137 : 80002,
      originAddress: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD',
      blacklist: false,
      verified: true,
      verifiedBy: 'Polygon',
      featured: true,
    },
    updatedAt: new Date().toISOString(),
  },
  tokenMetadata: {
    tokenId: '',
    name: 'Polygon',
    description: 'MATIC is the native currency of the Polygon network.',
    attributes: [],
    updatedAt: new Date().toISOString(),
  },
};

export const USDT: TokenBalance = {
  contractType: ContractType.ERC20,
  contractAddress:
    import.meta.env.VITE_ENV === 'prod'
      ? '0xdAC17F958D2ee523a2206206994597C13D831ec7'
      : '0x337610d27c682e347c9cd60bd4b3b107c9d34ddd',
  accountAddress: '',
  balance: '0',
  tokenID: '0',
  blockHash:
    '0x1c3eebf4c12febffecf5198c99facc110d4467c77a32da73cfea7802dc96b795', // Reemplaza con el hash del bloque
  blockNumber: 0,
  chainId: 1,
  contractInfo: {
    chainId: 97,
    address:
      import.meta.env.VITE_ENV === 'prod'
        ? '0xdAC17F958D2ee523a2206206994597C13D831ec7'
        : '0x337610d27c682e347c9cd60bd4b3b107c9d34ddd',
    name: 'USDT Token',
    type: 'ERC20',
    symbol: 'USDT',
    decimals: 18,
    logoURI: 'https://example.com/logoUSDT.png', // Reemplaza con la URI del logo
    deployed: true,
    bytecodeHash:
      '0x64dd4a6890e40688d52535d9dc7cd467a90a102dc7d51e644ba2b47ba10ecb5d', // Reemplaza con el hash del bytecode
    extensions: {
      link: 'https://tether.to',
      description:
        'USDT is a stablecoin that mirrors the price of the U.S. dollar.',
      ogImage: 'https://example.com/ogImageUSDT.png', // Reemplaza con la URI de la imagen OG
      originChainId: 1,
      originAddress:
        import.meta.env.VITE_ENV === 'prod'
          ? '0xdAC17F958D2ee523a2206206994597C13D831ec7'
          : '0x337610d27c682e347c9cd60bd4b3b107c9d34ddd',
      blacklist: false,
      verified: false,
      verifiedBy: 'Tether Team',
      featured: true,
    },
    updatedAt: new Date().toISOString(),
  },
  tokenMetadata: {
    tokenId: '',
    name: 'Tether',
    description:
      'USDT is a stablecoin that mirrors the price of the U.S. dollar.',
    attributes: [],
    updatedAt: new Date().toISOString(),
  },
};
