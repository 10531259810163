import router from '@/router';
import { useAuthStore } from '@/stores';
import { usePaymentsStore } from '@/stores/payments';
import { useResourcesStore } from '@/stores/resources';
import { useSocialStore } from '@/stores/social';
import { toast } from '@/utils/toast';
import { connectRapidMessagingSocket } from './rapid-messaging.socket';

export const coinsSocketEventType = {
  Reward: 'reward',
  MissionReward: 'mission_reward',
  Ping: 'ping',
  SuccessPurchase: 'success_purchase',
} as const;

export type CoinsSocketEventType =
  (typeof coinsSocketEventType)[keyof typeof coinsSocketEventType];

interface CoinsSocketEvent {
  coins: number;
  type: CoinsSocketEventType;
  userId: string;
}

interface MessageSocket {
  type: 'join' | 'leave';
  room: `coins-${string}`;
}

let coinsSocket: WebSocket | undefined;
let myUserId: string | undefined;
let reconnectTimeout: NodeJS.Timeout | undefined;
let retryCount = 0;

function attemptReconnect() {
  if (!myUserId) return;
  if (reconnectTimeout) {
    clearTimeout(reconnectTimeout); // Clear any existing reconnect attempt
  }

  const retryDelay = Math.min(Math.pow(2, retryCount) * 1000, 30000); // Exponential backoff with max delay
  retryCount++;

  console.log(
    `Attempting to reconnect coins socket in ${retryDelay.toString()}ms...`,
  );

  reconnectTimeout = setTimeout(() => {
    connectCoinsSocket(myUserId);
  }, retryDelay);
}

export const connectCoinsSocket = (userId?: string) => {
  myUserId = userId;
  if (coinsSocket) return coinsSocket;

  const paymentsStore = usePaymentsStore();
  const resourcesStore = useResourcesStore();

  const url = new URL(import.meta.env.VITE_API_PAYMENTS_URL);

  coinsSocket = new WebSocket(`wss://${url.host}${url.pathname}socket`);

  coinsSocket.addEventListener('open', () => {
    console.log('Connected to coins socket');

    retryCount = 0;

    if (coinsSocket && coinsSocket.readyState === WebSocket.OPEN) {
      if (!userId) return;
      // join room to start receiving messages
      coinsSocket.send(
        JSON.stringify({
          type: 'join',
          room: `coins-${userId}`,
        } satisfies MessageSocket),
      );
    }
  });

  coinsSocket.addEventListener('error', () => {
    console.log('Error connecting to coins socket');
  });

  coinsSocket.addEventListener(
    'message',
    async (event: MessageEvent<string>) => {
      try {
        const data = JSON.parse(event.data) as CoinsSocketEvent;
        switch (data.type) {
          case coinsSocketEventType.Ping:
            console.log('pong');
            return;
          case coinsSocketEventType.Reward:
            await paymentsStore.welcomeReward(data.coins);
            return;
          case coinsSocketEventType.MissionReward:
            await paymentsStore.missionReward(data.coins);
            return;
          case coinsSocketEventType.SuccessPurchase:
            await resourcesStore.checkoutComplete();
            return;
          default:
            console.log('Unknown coins socket event:', data);
            return;
        }
      } catch (e) {
        console.error(event.data);
      }
    },
  );

  coinsSocket.addEventListener('close', async (event) => {
    coinsSocket = undefined;
    console.log('Connection to coins socket closed:', event.code, event.reason);
    // For now this socket is used to disconnect the user when the cookie is refreshed with "undefined" response
    // it should be a custom ws or a global ws for all the app
    const resp = await useAuthStore().refreshCookie(); //success or error -> "undefined"
    if (!!resp && resp === 'conflict-another-device') {
      toast.info({
        title: "You've been disconnected",
        duration: 10_000,
        message:
          'You have been disconnected because you connected from another device',
      });
      useSocialStore().clearMyUser();
      await router.push({ path: '/' });
    } else if (resp) {
      // If the user remains logged in, the WebSockets are reconnected
      connectCoinsSocket(userId);
      connectRapidMessagingSocket(userId);
    }
    //Closing Status Codes:
    //
    // 1000 (Normal Closure): The connection was closed cleanly and intentionally. No reconnection needed.
    // 1001 (Going Away): The endpoint is going away (e.g., server shutting down). Reconnection might be possible later.
    // 1006 (Abnormal Closure): The connection was closed unexpectedly (e.g., network error). Reconnection is recommended.
    // 1011 (Internal Error): An unexpected condition prevented the server from fulfilling the request. Reconnection is worth attempting.
    if (event.code !== 1000) {
      // Don't reconnect if intentionally closed
      attemptReconnect();
    }
  });

  return coinsSocket;
};
