import { type StripeSubscription, type Product } from '@/services';
import { paymentsService } from '@/services/payments.service';
import { defineStore } from 'pinia';
import { useSocialStore } from './social';
import { queryClient } from '@/plugins/vue-query';
import { toast } from '@/utils/toast';

interface PaymentsState {
  subscriptions: Product[];
  packs: Product[];
  currentSubscription: StripeSubscription | undefined;
  paidSubscriptionPriceId: string | undefined;
  coins: number | undefined;
  selectedProduct: Product | undefined;
  showCheckoutModal: boolean;
  showWelcomeRewardModal: boolean;
  welcomeRewardCoins?: number;
}

export const usePaymentsStore = defineStore('payments', {
  state: (): PaymentsState => ({
    subscriptions: [],
    packs: [],
    currentSubscription: undefined,
    paidSubscriptionPriceId: undefined,
    coins: undefined,
    selectedProduct: undefined,
    showCheckoutModal: false,
    showWelcomeRewardModal: false,
    welcomeRewardCoins: undefined,
  }),
  getters: {
    currentSubscriptionPriceId(state) {
      return state.currentSubscription?.items.data[0].price.id;
    },
    currentSubscriptionCancelAtPeriodEnd(state) {
      return state.currentSubscription?.cancel_at_period_end;
    },
  },
  actions: {
    async getProducts() {
      const socialStore = useSocialStore();
      if (!socialStore._socialUser) return;
      try {
        const { data } = await queryClient.ensureQueryData({
          queryKey: ['payments-products', socialStore._socialUser._id],
          queryFn: () => {
            const request = paymentsService.products();
            queryClient.setQueryData(
              ['payments-products', socialStore._socialUser?._id],
              request,
            );
            return request;
          },
        });

        this.subscriptions = data.subscriptions;
        this.packs = data.packs;
        this.currentSubscription = data.subscription;
        this.paidSubscriptionPriceId = data.paidPriceId;
      } catch (error) {
        console.log(error);
      }
    },
    async getCoins() {
      const socialStore = useSocialStore();
      if (!socialStore._socialUser) return;
      try {
        const { data } = await queryClient.ensureQueryData({
          queryKey: ['payments-coins', socialStore._socialUser._id],
          queryFn: () => {
            const request = paymentsService.coins();
            queryClient.setQueryData(
              ['payments-coins', socialStore._socialUser?._id],
              request,
            );
            return request;
          },
        });

        this.coins = data.coins;
      } catch (error) {
        console.log(error);
      }
    },
    async welcomeReward(coins: number) {
      const socialStore = useSocialStore();

      this.welcomeRewardCoins = coins;
      this.showWelcomeRewardModal = true;

      queryClient.removeQueries({
        queryKey: ['payments-coins', socialStore._socialUser?._id],
      });

      await this.getCoins();
    },
    async missionReward(coins: number) {
      const socialStore = useSocialStore();

      toast.success({
        title: 'Mission completed!',
        message: `Congratulations! You have won ${String(coins)} coins`,
        duration: 4000,
        props: {
          image:
            'https://storage.googleapis.com/legendaryum-image/pack-500-legends',
        },
      });

      queryClient.removeQueries({
        queryKey: ['payments-coins', socialStore._socialUser?._id],
      });

      await this.getCoins();
    },
  },
});
