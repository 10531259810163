<script setup lang="ts">
import DirectMessageCard from '@/components/messages/DirectMessageCard.vue';
import useDirectMessages from '@/composables/useDirectMessages';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { computed } from 'vue';

const { chats, markChatsAsSeen, hasUnseenChats, getChatUser } =
  useDirectMessages();

const lastChats = computed(() => chats.value.slice(0, 10));
</script>

<template>
  <div>
    <Popover v-slot="{ open }" class="relative flex">
      <PopoverButton ref="buttonEl" class="relative outline-none" as="button">
        <IconifyIcon
          icon="icon-park-outline:message"
          class="text-white"
          width="25px"
        />
        <template v-if="hasUnseenChats">
          <span
            class="absolute -right-1 -top-1 mr-1 inline-block size-3 rounded-full bg-red-600"
          ></span>
        </template>
      </PopoverButton>
      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <PopoverPanel
          v-show="open"
          ref="panelEl"
          v-slot="{ close }"
          static
          class="absolute right-[-84px] top-6 z-10 min-w-96 scale-90 sm:right-0 sm:top-10 sm:scale-100"
        >
          <div
            class="relative flex flex-col items-center gap-3 rounded-xl bg-backgrounds-darkgrey px-2 py-3 pb-4"
          >
            <div
              class="flex w-full items-end justify-between border-b-2 border-solid border-white/50 pb-2 tracking-wider brightness-200"
            >
              <span>Direct messages</span>
              <span
                :class="{
                  'cursor-pointer text-xs font-light text-[#8E8E8E] transition-all hover:underline':
                    hasUnseenChats,
                  'text-xs text-gray-600': !hasUnseenChats,
                }"
                @click="hasUnseenChats ? markChatsAsSeen() : null"
                >Mark all as read</span
              >
            </div>

            <div
              class="flex size-full max-h-96 flex-col gap-2 overflow-y-scroll"
            >
              <template v-if="chats.length > 0">
                <DirectMessageCard
                  v-for="chat in lastChats"
                  :key="chat._id"
                  :user="getChatUser(chat)"
                  :item="chat"
                  @click="close"
                />
              </template>
              <template v-else>You have no messages, start chatting!</template>
            </div>
            <router-link to="/direct-messages" @click="close">
              <p
                class="font-medium text-darksecondary transition-colors duration-300 hover:text-white"
              >
                See all direct messages
              </p>
            </router-link>
            <span
              class="absolute bottom-0 h-1 w-1/3 rounded-t-md bg-[#8E8E8E]"
            ></span>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>

<style scoped lang="scss"></style>
