import axios from 'axios';
import type {
  SocialUser,
  Preference,
  SocialMedias,
  MinifiedSocialUser,
  ContactForm,
  ReportForm,
} from './social.service.types';
const backendUrl = import.meta.env.VITE_SOCIAL_URL;

const commonHeaders = {
  'Content-Type': 'application/json',
};

const socialApi = axios.create({
  baseURL: backendUrl,
  withCredentials: true,
  headers: { ...commonHeaders },
});
// username validation length
export const USERNAME_LENGTH = {
  MIN: 4,
  MAX: 16,
} as const;

export const socialService = {
  contact(data: ContactForm) {
    return socialApi.post<ContactForm>(`public/contact`, data);
  },
  report(data: ReportForm) {
    return socialApi.post<ReportForm>(`problemReport`, data);
  },
  createSocialUser() {
    return socialApi.post<SocialUser>(`socialUsers`);
  },
  updateSocialUser(data: Partial<SocialUser>) {
    return socialApi.put<SocialUser>(`socialUsers/update`, {
      data: data,
    });
  },
  generateRandomUsername() {
    return socialApi.get<{ nickname: string }>(`socialUsers/newNickname`);
  },
  isValidUsername(username: string) {
    return socialApi.post<{ valid: boolean }>(`socialUsers/validUsername`, {
      username: username,
    });
  },
  searchUsers(username: string, offset: number, limit: number) {
    return socialApi.get<MinifiedSocialUser[]>(
      `search/byUsername?username=${username}&limit=${limit.toString()}&offset=${offset.toString()}`,
    );
  },
  getUserById(id: string) {
    return socialApi.get<SocialUser>(`search/byId/${id}`);
  },
  getUsersById(ids: string[]) {
    return socialApi.post<MinifiedSocialUser[]>(`search/byIds`, { ids: ids });
  },
  getMyUser() {
    return socialApi.get<SocialUser>(`socialUsers/myUserDetails`);
  },
  getPreferences() {
    return socialApi.get<Preference[]>(`/preferences`);
  },
  uploadUserImage(userImg: string) {
    return socialApi.post<SocialUser>(`socialUsers/setUserImg`, {
      userImg,
    });
  },
  saveAvatar(RPMAvatarId: string) {
    return socialApi.post<SocialUser>(`/socialUsers/readyPlayerMe/saveAvatar`, {
      RPMAvatarId: RPMAvatarId,
    });
  },
  postAvatarUserId() {
    return socialApi.post<string>(`/socialUsers/readyPlayerMe/saveUserId`);
  },
  readyPlayerMeIframeToken() {
    return socialApi.get<{ subdomain: string; token: string }>(
      `/socialUsers/readyPlayerMe/iframeToken`,
    );
  },
  addPreferences(preferencesIds: string[]) {
    return socialApi.put<SocialUser>(`/socialUsers/addPreferences`, {
      preferencesId: preferencesIds,
    });
  },
  removePreferences(preferencesIds: string[]) {
    return socialApi.put<SocialUser>(`/socialUsers/removePreferences`, {
      preferencesId: preferencesIds,
    });
  },
  uploadSocialMedias(socialMedias: SocialMedias) {
    return socialApi.post('/socialUsers/uploadSocialMedias', {
      socialMedias: socialMedias,
    });
  },
  updateInteraction(userId: string, like: boolean) {
    return socialApi.put(`/socialUsers/interactions`, {
      targetUserId: userId,
      like: like,
    });
  },
  blockUser(userId: string) {
    return socialApi.put<{ message: string }>(
      `/socialUsers/blockUser/${userId}`,
    );
  },
  unblockUser(userId: string) {
    return socialApi.put(`/socialUsers/unblockUser/${userId}`);
  },
  follow(userId: string) {
    return socialApi.put(`/followers/follow?userToFollowId=${userId}`);
  },
  unfollow(userId: string) {
    return socialApi.put(`/followers/unfollow?userToUnfollowId=${userId}`);
  },
  getMyFollowers() {
    return socialApi.get<MinifiedSocialUser[]>(`/followers/bringFollowers/`);
  },
  getMyFollowing() {
    return socialApi.get<MinifiedSocialUser[]>(`/followers/bringFollowing/`);
  },
  getFollowers(userId: string) {
    return socialApi.get<MinifiedSocialUser[]>(
      `/followers/bringFollowers/${userId}`,
    );
  },
  getFollowing(userId: string) {
    return socialApi.get<MinifiedSocialUser[]>(
      `/followers/bringFollowing/${userId}`,
    );
  },
  hasBlockedMe(userId: string) {
    return socialApi.get<{ hasBlockedMe: boolean }>(
      `/socialUsers/hasBlockedMe?userId=${userId}`,
    );
  },
};
