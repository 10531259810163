<script setup lang="ts">
import { AuthRequest } from '@/services';
import { getQueryParams, getRouteToGo } from '@/utils/popup';
import { ref, reactive } from 'vue';
import { useRouter } from 'vue-router';
import AuthInput from '@/components/auth/AuthInput.vue';
import EmailInput from '@/components/auth/EmailInput.vue';
import { useAuthStore } from '@/stores/auth';
import LegendaryumLogo from '@/components/icons/nav/LegendaryumLogo.vue';
import ButtonComponent from '@/components/common/ButtonComponent.vue';
import { handleAxiosError } from '@/utils/error';

const router = useRouter();
const authStore = useAuthStore();
const isLoading = ref(false);
const credentialsError = ref(false);
const credentialsErrorMessage = ref('');

const props = defineProps<{ email: string }>();
const emits = defineEmits<{
  (e: 'back'): void;
  (e: 'recovery'): void;
  (e: 'on:close'): void;
}>();

const loginData = reactive({
  email: props.email,
  password: '',
});

// function redirectToResetPassword() {
//   emits('recovery');
// }

const handlePassword = (password: string) => {
  loginData.password = password;
};

const onLoginClick = async () => {
  try {
    isLoading.value = true;
    const authData = {
      email: loginData.email,
      password: loginData.password,
    } as AuthRequest;
    await authStore.login(authData.email, authData.password);
    emits('on:close');
  } catch (error) {
    const { message } = handleAxiosError(error);

    credentialsError.value = true;
    credentialsErrorMessage.value = message;

    return;
  } finally {
    isLoading.value = false;
  }

  const path = getRouteToGo();
  await router.push({
    path: path ?? '/',
    query: { ...getQueryParams(path) },
  });
};
</script>

<template>
  <form @submit.prevent="onLoginClick">
    <div class="grid grid-cols-1">
      <div class="flex flex-col">
        <div class="grid grid-cols-1">
          <div class="flex flex-col justify-center">
            <div class="mb-4 flex flex-col items-center justify-center">
              <LegendaryumLogo class="mr-8 hidden md:block" />
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1">
          <div class="flex flex-col">
            <p class="text-md text-center">
              Enter your email and password to continue
            </p>
            <div class="relative my-3 mb-5 flex flex-col">
              <EmailInput
                v-model="loginData.email"
                :error="credentialsError"
                :title="`Email`"
                name="email"
              />
              <AuthInput
                v-model="loginData.password"
                :error="credentialsError"
                :title="`Password`"
                :input-type="`password`"
                class="mt-5"
                @update="handlePassword"
              />
              <!--              <div class="mt-1">-->
              <!--                <div class="flex flex-col">-->
              <!--                  <button-->
              <!--                    type="button"-->
              <!--                    class="text-right text-sm italic text-secondary transition-all duration-300 hover:text-secondary/80 disabled:!text-gray-500"-->
              <!--                    :disabled="isLoading"-->
              <!--                    @click="redirectToResetPassword"-->
              <!--                  >-->
              <!--                    Recover password-->
              <!--                  </button>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="mt-4 flex justify-center">
                <p
                  v-if="credentialsError"
                  class="w-full rounded-md bg-[#50191d] px-3 py-1 text-center text-[#f88f96]"
                >
                  {{ credentialsErrorMessage }}
                </p>
              </div>
              <div class="mt-5 flex items-center justify-center gap-5">
                <ButtonComponent
                  width="sm"
                  type="button"
                  variant="secondary"
                  :disabled="isLoading"
                  @click="emits('back')"
                >
                  Back
                </ButtonComponent>
                <ButtonComponent
                  width="sm"
                  type="submit"
                  :is-loading="isLoading"
                >
                  Continue
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<style lang="scss" scoped>
.index {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #161616;
  overflow: auto;
  color: white;
  justify-content: center;

  .container {
    width: 360px;

    .legendaryum-logo {
      height: 200px;
    }

    .social-button {
      background-position: 25px 0px;
      box-sizing: border-box;
      color: rgb(255, 255, 255);
      cursor: pointer;
      display: inline-block;
      height: 50px;
      line-height: 50px;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      vertical-align: middle;
      width: 100%;
      border-radius: 30px;
      outline: rgb(255, 255, 255) none 0px;
      transition: all 0.2s cubic-bezier(0.72, 0.01, 0.56, 1) 0s;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      width: 350px;
      margin-bottom: 10px;

      @media (max-width: '450px') {
        width: 300px;
      }
    }
  }
}
</style>
